import React, { useState } from "react";
import { Drawer, theme, Space, Button, Flex, Tooltip, message } from "antd";
import {
	ArrowLeftOutlined,
	MenuOutlined,
	StarOutlined,
	EyeOutlined,
	LinkOutlined,
} from "@ant-design/icons";
import ShareRecipeSlider from "./ShareRecipeSlider";
import { addToCookbook } from "./configureUserProfile";

const RecipeOptionsSlider = ({ recipe, handleRecipeClick }) => {
	const { token } = theme.useToken();
	const [open, setOpen] = useState(false);
	const showDrawer = () => {
		setOpen(true);
	};
	const onClose = () => {
		setOpen(false);
	};
	const containerStyle = {
		position: "absolute",
		height: "100%",
		top: -1,
		bottom: 0,
		left: -1,
		right: 1,
		width: "101%",
		padding: 0,
		overflow: "hidden",
		textAlign: "center",
		background: token.colorFillAlter,
		border: `1px solid ${token.colorBorderSecondary}`,
		borderRadius: token.borderRadiusLG,
		// backdropFilter: 'blur(10px)',
		// WebkitBackdropFilter: 'blur(10px)',
	};

	const IconText = ({ icon, text, tooltip = "", fx = null }) => (
		<Space
			onClick={fx ?? showDrawer}
			style={{
				cursor: "pointer",
			}}
			tooltip={tooltip}
		>
			<Tooltip title={tooltip}>
				{React.createElement(icon)}
				{text}
			</Tooltip>
		</Space>
	);

	const handleRecipeSave = async (recipe) => {
		console.log(recipe);
		await addToCookbook(recipe);
	};

	return (
		<div style={containerStyle}>
			<div
				style={
					{
						//   marginTop: 16,
					}
				}
			>
				<div
					className="recipe-card-buttons"
					style={{
						display: "flex",
						position: "absolute",
						bottom: "20px",
						flexDirection: "row",
						width: "100%",
						alignItems: "stretch",
						justifyItems: "stretch",
						justifyContent: "space-around",
						overflow: "hidden",
						zIndex: 10,
					}}
				>
					{window.location.pathname !== "/cookbook" ? (
						<IconText
							icon={StarOutlined}
							text=""
							tooltip="Save to your cookbook"
							fx={() => {
								handleRecipeSave(recipe);
							}}
							key="save"
						/>
					) : (
						""
					)}

					<ShareRecipeSlider recipe={recipe} />

					<IconText
						icon={MenuOutlined}
						text=""
						tooltip="More options"
						key="options"
					/>
				</div>
			</div>

			<Drawer
				drawerStyle={{
					backdropFilter: "blur(10px)",
					WebkitBackdropFilter: "blur(10px)",
				}}
				style={{
					backgroundColor: "transparent",
				}}
				header={false}
				height="100%"
				mask={true}
				title={
					<div style={{ fontSize: "13px", lineHeight: 1.1 }}>
						{recipe.title}
					</div>
				}
				headerStyle={{
					fontSize: "13px",
					textOverflow: "ellipsis",
					padding: "15px",
				}}
				contentWrapperStyle={{
					// padding: "5px",
					backgroundColor: "#00000090",
					// backdropFilter: 'blur(6px)',
					// WebkitBackdropFilter: 'blur(10px)',
				}}
				closeIcon={<ArrowLeftOutlined />}
				placement="bottom"
				closable={true}
				onClose={onClose}
				open={open}
				getContainer={false}
			>
				<Flex
					gap="small"
					style={{
						width: "100%",
						justifyContent: "space-around",
						alignItems: "center",
						textAlign: "center",
						padding: "0",
						margin: "0",
						flexDirection: "column",
					}}
				>
					<Button
						type="primary"
						icon={<EyeOutlined />}
						onClick={(event) => {
							handleRecipeClick(recipe, event);
						}}
						style={{
							width: "100%",
						}}
					>
						View Recipe
					</Button>
					<Button
						style={{ width: "100%" }}
						icon={<LinkOutlined />}
						href={`/recipe/${recipe.recipeId}`}
						target="_blank"
					>
						Direct Link
					</Button>

					{window.location.pathname === "/cookbook" ? (
						<Button style={{ width: "100%" }} type="dashed" danger={true}>
							Delete Recipe
						</Button>
					) : null}
				</Flex>
			</Drawer>
		</div>
	);
};
export default RecipeOptionsSlider;
