// eslint-disable-next-line
import React, { useState } from "react";
// eslint-disable-next-line
import { Form, Select, InputNumber, Divider, Collapse } from "antd";

const RecipeExtrasForm = () => {
	const savedFormData = JSON.parse(localStorage.getItem("newRecipeForm"));

	let formData =
		{ goals: savedFormData?.goals, ...savedFormData?.macros } ?? {};

	const handleChange = (value, allValues) => {
		formData = allValues;
		// console.log(formData);
		const existingFormData = JSON.parse(localStorage.getItem("newRecipeForm"));

		let macros = formData;
		let goals = formData?.goals;
		delete macros.goals;

		const currentFormData = {
			...existingFormData,
			macros: macros || null,
			goals: goals || [],
		};

		localStorage.setItem("newRecipeForm", JSON.stringify(currentFormData));
	};

	return (
		<Form
			labelCol={{ md: { span: 15, offset: 0 }, xs: { span: 14, offset: 0 } }}
			// wrapperCol = {{ span: 45, offset: 0 }}

			layout="inline"
			initialValues={formData}
			onValuesChange={handleChange}
			colon={false}
			size="large"
			labelAlign="left"
			labelWrap={true}
			style={{
				width: "100%",
				margin: ["0", "auto"],
				display: "flex-block",
				flexDirection: "column",
				justifyContent: "space-around",
				justifyItems: "space-between",
				alignItems: "space-around",
				alignContent: "space-around",
				textAlign: "right",
			}}
		>
			<h2 style={{ textAlign: "left" }}>Optional Settings</h2>
			<p style={{ textAlign: "left" }}>
				You can add more details in this section below — otherwise, tap{" "}
				<b>FINISH</b> to get your recipe.
			</p>
			<br />
			<Divider orientation="center">
				<b>Personal Goals</b>
			</Divider>
			<Form.Item
				labelCol={{ md: { span: 10, offset: 0 }, xs: { span: 6, offset: 0 } }}
				label="Health and fitness goals:"
				name="goals"
			>
				<Select
					mode="tags"
					size="large"
					placeholder="Select your health and fitness goals..."
					options={[
						"Maintain Weight",
						"Lose Weight / Get Leaner",
						"Gain Weight / Bulk Up",
						"Body Recomposition",
					].map((item) => ({ label: item, value: item }))}
					allowClear={true}
				/>
			</Form.Item>
			<br />
			<Divider
				orientation="center"
				style={{
					whiteSpace: "pre-wrap",
				}}
			>
				<b>Set Calories & Macro Targets</b>
			</Divider>
			<Collapse
				style={{
					textAlign: "left",
				}}
				items={[
					{
						key: "1",
						label: "Show Advanced Settings",
						children: (
							<>
								<h2>
									Calories
									<hr />
								</h2>
								<Form.Item
									className="advanced-option-input"
									label="Max calories per serving:"
									name="maxCaloriesPerServing"
								>
									<InputNumber controls={false} addonAfter="cal" />
								</Form.Item>

								<h2>
									Fat
									<hr />
								</h2>
								<Form.Item
									className="advanced-option-input"
									label="Max fat content per serving:"
									name="maxFatPerServing"
								>
									<InputNumber controls={false} addonAfter="g" />
								</Form.Item>

								<h2>
									Carbohydrates
									<hr />
								</h2>
								<Form.Item
									className="advanced-option-input"
									label="Min amount of net carbs (excluding fiber) per serving:"
									name="minCarbsPerServing"
								>
									<InputNumber controls={false} addonAfter="g" />
								</Form.Item>

								<Form.Item
									className="advanced-option-input"
									label="Max amount of net carbs (excluding fiber) per serving:"
									name="maxCarbsPerServing"
								>
									<InputNumber controls={false} addonAfter="g" />
								</Form.Item>

								<Form.Item
									className="advanced-option-input"
									label="Max amount of added sugar per serving:"
									name="maxSugarPerServing"
								>
									<InputNumber controls={false} addonAfter="g" />
								</Form.Item>

								<h2>
									Fiber
									<hr />
								</h2>
								<Form.Item
									className="advanced-option-input"
									label="Minimum amount of fiber per serving:"
									name="minFiberPerServing"
								>
									<InputNumber controls={false} addonAfter="g" />
								</Form.Item>

								<Form.Item
									className="advanced-option-input"
									label="Max amount of fiber per serving:"
									name="maxFiberPerServing"
								>
									<InputNumber controls={false} addonAfter="g" />
								</Form.Item>

								<h2>
									Protein
									<hr />
								</h2>
								<Form.Item
									className="advanced-option-input"
									label="Minimum amount of protein per serving:"
									name="minProteinPerServing"
								>
									<InputNumber controls={false} addonAfter="g" />
								</Form.Item>

								<Form.Item
									className="advanced-option-input"
									label="Maximum amount of protein per serving:"
									name="maxProteinPerServing"
								>
									<InputNumber controls={false} addonAfter="g" />
								</Form.Item>
							</>
						),
					},
				]}
			/>
		</Form>
	);
};

export default RecipeExtrasForm;
