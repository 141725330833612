import { useEffect, useState } from "react";
import supabase from "./supabaseClient";
import React from "react";
import { List, Card, Modal, Button, message } from "antd";
import ViewRecipe from "./ViewRecipe";
import stripHTML from "./stripHTML";
import loading from "./loading-infinity.gif";
import moment from "moment";
import ImageLoader from "./ImageLoader";
import NavBar from "./NavBar";
import RecipeOptionsSlider from "./RecipeOptionsSlider";

function MyCookbook() {
	const userId =
		localStorage.getItem("user") !== "undefined"
			? JSON.parse(localStorage?.getItem("user"))?.id
			: null;

	const [recipes, setRecipes] = useState([]);

	useEffect(() => {
		const getRecipes = async () => {
			const { data, error } = await supabase
				.from("user_cookbooks")
				.select("all_recipes(*)")
				.eq("user_id", userId);
			// console.log(JSON.stringify(data));
			if (
				(error || data.length === 0) &&
				window.location.pathname.includes("cookbook")
			) {
				return [];
			} else {
				let userRecipes = data.map((recipe) => recipe.all_recipes);
				return userRecipes;
			}
		};

		getRecipes().then((data) => setRecipes(data));

		const intervalId = setInterval(() => {
			getRecipes().then((data) => setRecipes(data));
		}, 3000);

		return () => clearInterval(intervalId);
	}, [userId]);

	const [modal1Open, setModal1Open] = useState(false);

	const data = recipes
		.sort((a, b) => new Date(b?.created_at) - new Date(a?.created_at))
		.map((recipe) => ({
			recipeId: recipe?.recipeId,
			href: "",
			title: `${
				stripHTML(recipe?.recipe?.split("</h1>")[0])?.replace("Title: ", "") ??
				recipe?.foodRequest?.toUpperCase() ??
				"New Recipe"
			}`,
			content: `${recipe?.recipe}`,
			image: `${recipe?.image ?? loading ?? "https://iili.io/HZaJR6u.jpg"}`,
			status: recipe?.status,
			createdAt: recipe?.created_at,
			diets: recipe?.diets?.join(", ") ?? "Flexible Diet",
			allergies: recipe?.allergies?.join(", ") ?? "",
		}));

	const handleRecipeClick = (recipeObj, event) => {
		event.preventDefault();
		if (recipeObj.status === "complete") {
			localStorage.setItem("selectedRecipe", JSON.stringify(recipeObj));
			setModal1Open(true);
		} else {
			message.loading(
				"This recipe is still being generated. It will be ready within the next 30 seconds!",
			);
		}
	};

	return (
		<>
			<NavBar />
			<br />
			<h1 style={{ textAlign: "center" }}>My Cookbook</h1>
			<hr style={{ borderColor: "#FFFFFF30" }} />
			<br />
			<br />
			<List
				grid={{
					gutter: 0,
					xs: 1,
					sm: 2,
					md: 2,
					lg: 3,
					xl: 3,
					xxl: 4,
				}}
				itemLayout="horizontal"
				size="medium"
				style={{
					margin: "0 auto",
					paddingBottom: 80,
				}}
				pagination={{
					hideOnSinglePage: true,
					onChange: (page) => {
						console.log(page);
					},
					pageSize: 50,
				}}
				loading={recipes.length === 0}
				dataSource={data}
				footer={
					recipes.length === 0 ? (
						<>
							<div style={{ textAlign: "center" }}>
								<b>
									You have no recipes yet. Click <a href="/create">here</a> to
									create one!
								</b>
								<br />
								<br />
								<Button
									type="primary"
									href="/create"
									style={{
										margin: "0 auto",
										fontWeight: "500",
										backgroundColor: "transparent",
										borderWidth: "2px",
										borderColor: "#21ab75",
										borderStyle: "solid",
										paddingBottom: "25px",
										boxShadow: "0 26px 30px rgba(0,0,0,0.1)",
									}}
								>
									Create a Custom Recipe
								</Button>
							</div>
						</>
					) : (
						""
					)
				}
				renderItem={(item) => (
					<>
						<List.Item key={item.id} actions={[]} extra={<div></div>}>
							<Card
								className="recipe-card"
								// title={<a href="/" onClick={ (event) => handleRecipeClick(item, event) }> {item.title}</a>}
								style={{
									boxShadow: "0 26px 30px rgba(0,0,0,0.1)",
									backgroundColor: "#1a424f",
									margin: "0 auto",
									marginBottom: "40px",
									overflow: "hidden",
									minHeight: "350px",
								}}
							>
								{/* card content goes here */}
								<RecipeOptionsSlider
									recipe={item}
									handleRecipeClick={handleRecipeClick}
								/>

								<div className="recipe-image-container">
									<a
										href="/"
										onClick={(event) => handleRecipeClick(item, event)}
									>
										<div
											className="recipe-timestamp"
											style={{
												display: "flex",
												justifyContent: "flex-start",
												justifyItems: "flex-start",
												position: "absolute",
												zIndex: 10,
												top: 0,
												fontSize: "x-small",
												lineHeight: 1,
												textAlign: "left",
												width: "100%",
												color: "white",
											}}
										>
											<div
												style={{
													background: `linear-gradient(-90deg, transparent 50%, #153842 90%)`,
													borderTopLeftRadius: 5,
													borderBottomRightRadius: 5,
													padding: 5,
													textShadow: "0 0 5px black",
													width: "100%",
												}}
											>
												🍽️{" "}
												{moment(item?.createdAt).fromNow() ||
													"Customized for You"}
											</div>
										</div>
										<ImageLoader
											className="recipe-image"
											alt={item?.title}
											src={`${item?.image}`}
											style={{
												textAlign: "center",
												margin: "0",
												padding: "0",
												maxWidth: item.image === loading ? "100px" : "100%",
												// overflow: "hidden"
											}}
										/>
									</a>
								</div>

								<div
									className="recipe-card-title"
									style={{
										position: "absolute",
										zIndex: 10,
										textAlign: "center",
										width: "100%",
									}}
								>
									<a
										href="/"
										onClick={(event) => handleRecipeClick(item, event)}
									>
										{item.title}
									</a>
									<br />
									<br />
									<span
										className="diet-list-card"
										style={{
											display: "block",
											fontWeight: "light",
											fontSize: "x-small",
											lineHeight: 1,
										}}
									>
										{item.diets}
									</span>
								</div>
							</Card>
						</List.Item>
					</>
				)}
			/>
			{modal1Open ? (
				<Modal
					className="recipe-modal"
					title=""
					style={{
						maxWidth: "800px",
						overflow: "hidden",
					}}
					width={"85%"}
					open={modal1Open}
					onOk={() => setModal1Open(false)}
					onCancel={() => setModal1Open(false)}
					footer={null}
				>
					<ViewRecipe />
				</Modal>
			) : (
				<></>
			)}
		</>
	);
}

export default MyCookbook;
