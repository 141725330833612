import { Flex, Spin } from "antd";

export default function Loader() {
	// console.log("Loader shown.");

	return (
		<>
			<Flex align="center" gap="middle">
				<Spin size="large" spinning={true} />
			</Flex>
		</>
	);
}
