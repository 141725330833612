import axios from "axios";
import supabase from "./supabaseClient";
import { message } from "antd";

export default async function generateRecipe(params) {
	const { data, error } = await supabase.auth.refreshSession();
	const { session, user } = data;

	// console.log({user: user, session: session});

	if (error || !user) {
		message.error(
			"So sorry! Something went wrong. Would you mind trying again? 😊",
		);
	}

	const userJWT = session.access_token;

	const options = {
		method: "POST",
		url: "https://hmdakrlcklahbuzimglq.supabase.co/functions/v1/generateRecipe",
		headers: {
			Authorization: "Bearer " + userJWT,
			"Content-Type": "application/json",
		},
		data: { userInput: { ...params, token: userJWT } },
	};
	console.log(options);
	axios(options)
		.then(function (response) {
			console.log(response.data);
		})
		.catch(function (error) {
			console.log(error);
		});
}
