import React, { useState } from "react";
import { Steps, theme, FloatButton, notification } from "antd";
import "./CreateRecipe.css";
import RecipeStartForm from "./RecipeStartForm";
import RecipePreferencesForm from "./RecipePreferencesForm";
import RecipeExtrasForm from "./RecipeExtrasForm";
import generateRecipe from "./generateRecipe";
import supabase from "./supabaseClient";
import { useNavigate } from "react-router-dom";
import NavBar from "./NavBar";

const steps = [
	{
		title: "Recipe Type",
		content: <RecipeStartForm />,
	},
	{
		title: "Preferences",
		content: <RecipePreferencesForm />,
	},
	{
		title: "Extras",
		content: <RecipeExtrasForm />,
	},
];

export default function CreateRecipe() {
	const { token } = theme.useToken();
	const [current, setCurrent] = useState(0);
	const next = () => {
		setCurrent(current + 1);
	};
	const prev = () => {
		setCurrent(current - 1);
	};
	const items = steps.map((item) => ({
		key: item.title,
		title: item.title,
	}));
	const contentStyle = {
		lineHeight: "260px",
		textAlign: "center",
		color: token.colorTextTertiary,
		backgroundColor: token.colorFillAlter,
		borderRadius: token.borderRadiusLG,
		border: `1px dashed ${token.colorBorder}`,
		marginTop: 16,
	};

	const onChange = (value) => {
		console.log("onChange:", value);
		setCurrent(value);
	};

	const navigate = useNavigate();

	const handleFormSubmit = async () => {
		const recipeId = () => {
			return Math.random().toString(36).substring(2, 100);
		};
		const userId =
			localStorage.getItem("user") !== "undefined"
				? JSON.parse(localStorage?.getItem("user"))?.id
				: null;
		let formData = JSON.parse(localStorage.getItem("newRecipeForm"));
		formData = { ...formData, recipeId: recipeId(), user_id: userId };
		// console.log(formData);
		localStorage.setItem("newRecipeForm", JSON.stringify(formData));

		let recipeIdDb;

		// UPLOAD THE RECIPE REQUEST TO SUPABASE
		const { data, error } = await supabase
			.from("all_recipes")
			.insert(formData)
			.select();
		console.log(data);
		// GET THE AUTO-GENERATED RECIPE_ID which is a UUID
		let cookbookRecord;
		if (data) {
			recipeIdDb = data[0].id;

			cookbookRecord = {
				recipe_id: recipeIdDb,
				user_id: userId,
				cookbook_id: "0",
			};
		}

		const { cookbookData, errorData } = await supabase
			.from("user_cookbooks")
			.insert(cookbookRecord);

		if (errorData) {
			console.log(error);
			return error;
		} else if (cookbookData) {
			console.log("Added to cookbook successfully!");
		}

		// ONCE THE RECIPE REQUEST IS UPLOADED, GENERATE THE RECIPE WITH OUR SUPABASE EDGE FUNCTION
		if (!error) {
			generateRecipe(formData);

			navigate("/cookbook");

			return data;
		} else {
			// alert("Failed.");
			console.log(error);
			return error;
		}
	};

	const NavButtons = () => {
		return (
			<>
				<FloatButton.Group
					shape="square"
					style={{
						display: "flex",
						flexDirection: "row",
						width: "100vw",
						left: 0,
						bottom: 60,
						// paddingBottom: "50px",
						padding: "25px 20px 50px 20px",
						boxShadow: "none",
						// background: "linear-gradient(155deg, skyblue, #f5f5f5)",
					}}
				>
					{current > 0 && (
						<FloatButton
							shape="square"
							description={
								<span className="float-button-text">&larr; Back</span>
							}
							// tooltip={<span>&larr; Back</span>}
							onClick={() => prev()}
							style={{
								width: "100%",
								backgroundColor: "#25414e",
								marginRight: "10px",
							}}
						></FloatButton>
					)}

					{current < steps.length - 1 && (
						<FloatButton
							type="primary"
							onClick={() => next()}
							shape="square"
							description={
								<span className="float-button-text">Next &rarr;</span>
							}
							// tooltip={<span>Next &rarr;</span>}
							style={{
								width: "100%",
							}}
						></FloatButton>
					)}
					{current === steps.length - 1 && (
						<FloatButton
							type="primary"
							shape="square"
							description={<span className="float-button-text">FINISH</span>}
							// tooltip={<span>FINISH</span>}
							onClick={() => {
								handleFormSubmit();
								notification.success({
									message: "Cooking Up Your Dish",
									description:
										"Building your custom recipe! It will be ready in just a moment. 👨🏻‍🍳",
									duration: 3,
								});
							}}
							style={{
								width: "100%",
							}}
						></FloatButton>
					)}
				</FloatButton.Group>
			</>
		);
	};

	return (
		<>
			<NavBar />
			<br />
			<h1 style={{ textAlign: "center" }}>Create Recipe</h1>

			<div className="create-recipe-container">
				<Steps
					direction="horizontal"
					labelPlacement="horizontal"
					responsive={false}
					type="default"
					width="100%"
					current={current}
					items={items}
					onChange={onChange}
					style={{
						flexDirection: "row",
					}}
				/>

				<div style={{ marginBottom: 24 }}>
					<NavButtons style={{ display: "none" }} />
				</div>

				<div className="create-recipe-box" style={contentStyle}>
					{steps[current].content}
				</div>

				<div style={{ marginTop: 24 }}>{/* <NavButtons /> */}</div>
			</div>
		</>
	);
}
