// eslint-disable-next-line
import React, { useState } from "react";
// eslint-disable-next-line
import { Form, Select } from "antd";

const RecipePreferencesForm = () => {
	const savedFormData = JSON.parse(localStorage.getItem("newRecipeForm"));

	let formData = savedFormData ?? {};

	const handleChange = (value, allValues) => {
		formData = allValues;
		// console.log(formData);
		const existingFormData = JSON.parse(localStorage.getItem("newRecipeForm"));
		localStorage.setItem(
			"newRecipeForm",
			JSON.stringify({ ...existingFormData, ...formData }),
		);
	};

	const allergyList = [
		"Peanuts",
		"Tree Nuts",
		"Dairy",
		"Lactose",
		"Casein",
		"Whey",
		"Egg",
		"Soy",
		"Sesame",
		"Wheat",
		"Gluten",
		"Mustard & Mustard Seed",
		"Fish",
		"Shellfish",
		"Celery",
		"Carrots",
		"Avocado",
		"Potato",
		"Pumpkin",
		"Mushroom",
		"Onion",
		"Garlic",
		"Almonds",
		"Coconut",
		"Phenylalanine",
		"Nutmeg",
		"Food Dyes",
		"Yeast",
		"Pea",
		"Corn",
		"Barley",
		"Beans",
		"Plum",
		"Orange",
		"Oat",
		"Rye",
		"Rice",
		"Mackerel",
		"Goat's Milk",
		"Gliadin",
		"Lobster",
		"Cranberry",
		"Durum Wheat",
		"Plaice",
		"Tomato",
		"Peppers",
		"Paprika",
		"Eggplant",
		"Broccoli",
		"Spinach",
		"Salmon",
		"Apple",
	].sort();
	const [selectedAllergies, setSelectedAllergies] = useState([]);
	const filteredAllergies = allergyList
		.filter((o) => !selectedAllergies.includes(o))
		.sort();

	const dietList = [
		"DASH Diet",
		"Diabetic Diet",
		"Elemental Diet",
		"Gluten-Free Diet",
		"Gluten-Free, Casein-Free Diet",
		"Healthy Kidney Diet",
		"Ketogenic (Keto) Diet",
		"Low-FODMAP Diet",
		"Low Carbohydrate (Low Carb) Diet",
		"Paleolithic (Paleo) Diet",
		"Pegan Diet",
		"Superfood Diet",
		"Whole30 Diet",
		"Atkins Diet",
		"Bulletproof Diet",
		"Dukan Diet",
		"High-Protein Diet",
		"Zone Diet",
		"South Beach",
		"Protein-Sparing Modified Fast (PSMF)",
		"Intermittent Fasting",
		"Fruitarian Diet",
		"Pescatarian Diet",
		"Vegetarian Diet",
		"Vegan Diet",
		"Plant-Based Diet",
		"Organic Diet",
		"Lacto Vegetarian Diet",
		"Ovo Vegatarian Diet",
		"Ovo-Lacto Vegetarian Diet",
		"Alkaline Diet",
		"Inuit Diet",
		"Jenny Craig",
		"Carnivore Diet",
		"Ketovore Diet",
		"Low-Fat Diet",
		"Low Glycemic Index Diet",
		"Low-Protein Diet",
		"Low Salt / Low Sodium Diet",
		"Raw Food Diet",
		"Low-Sugar Diet",
		"Sugar-Free Diet",
		"Grain-Free Diet",
		"Standard Healthy Diet",
	];
	const [selectedDiets, setSelectedDiets] = useState([]);
	const filteredDiets = dietList.filter((o) => !selectedDiets.includes(o));

	return (
		<Form
			labelCol={{}}
			wrapperCol={{}}
			layout="vertical"
			initialValues={formData}
			onValuesChange={handleChange}
			size="large"
			style={{
				width: "100%",
				textAlign: "left",
			}}
		>
			<h2>Follow nutrition plans.</h2>
			<Form.Item
				label="Choose or enter diets or nutrition plans your recipe should follow."
				name="diets"
			>
				<Select
					mode="tags"
					size="large"
					placeholder="Choose diets or nutrition plans..."
					value={selectedDiets}
					onChange={setSelectedDiets}
					options={filteredDiets.map((item) => ({
						value: item,
						label: item,
					}))}
					allowClear={true}
				/>
			</Form.Item>
			<hr />
			<h2>Exclude allergens and food intolerances.</h2>
			<Form.Item
				label="Select any ingredients from the list (or add your own custom ones) that should be avoided in your recipe."
				name="allergies"
			>
				<Select
					mode="tags"
					size="large"
					placeholder="Enter ingredients to avoid..."
					value={selectedAllergies}
					onChange={setSelectedAllergies}
					options={filteredAllergies.map((item) => ({
						value: item,
						label: item,
					}))}
					allowClear={true}
				/>
			</Form.Item>
		</Form>
	);
};

export default RecipePreferencesForm;
