import { useState, useEffect } from "react";
import "./App.css";
import loading from "./loading-infinity.gif";

const ImageLoader = ({ src, alt, className, style }) => {
	const [loaded, setLoaded] = useState(false);
	const placeholderImage =
		"https://cdn.vev.design/cdn-cgi/image/f=auto,q=82,fit=cover,w=1080,h=1080/private/0MYkhH8KEqMUT9tvAuVx9FwEn8K2/image/0nY5Usp3EB.png";

	useEffect(() => {
		const img = new Image();
		img.src = src || placeholderImage;
		img.onload = () => setLoaded(true);
	}, [src]);

	if (!loaded) {
		return (
			<div
				className={className}
				style={{ ...style, backgroundImage: loading }}
			></div>
		);
	}

	return <img className={className} alt={alt} src={src} style={style} />;
};

export default ImageLoader;
