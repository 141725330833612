// eslint-disable-next-line
import React, { useEffect, useState } from "react";
// eslint-disable-next-line
import { Form, Select, InputNumber, Divider, Collapse, Input, Button, Space } from "antd";
import { handleAccountChanges, getUserProfile } from "./configureUserProfile";

const AccountDetailsForm = () => {
	const [currentProfile, setProfile] = useState({});

	useEffect(() => {
		const fetchProfile = async () => {
			const profile = await getUserProfile();
			if (profile) {
				setProfile(profile);
				// console.log("Your name is: " + profile.name);
			}
		};

		fetchProfile();
	}, []);

	return (
		<Form
			key={
				Object.keys(currentProfile).length > 0
					? "profileLoaded"
					: "loadingProfile"
			}
			initialValues={currentProfile}
			labelCol={{ md: { span: 15, offset: 0 }, xs: { span: 14, offset: 0 } }}
			// wrapperCol = {{ span: 45, offset: 0 }}
			layout="horizontal"
			onFinish={handleAccountChanges}
			colon={false}
			size="large"
			labelAlign="left"
			labelWrap={true}
			style={{
				width: "100%",
				margin: ["0", "auto"],
				display: "flex-block",
				flexDirection: "column",
				justifyContent: "space-around",
				justifyItems: "space-between",
				alignItems: "space-around",
				alignContent: "space-around",
				textAlign: "right",
			}}
		>
			<h2 style={{ textAlign: "center" }}>Account Information</h2>
			<br />
			<p>
				Your Account Email: <b>{currentProfile?.email}</b>
			</p>
			<Form.Item
				id="name"
				labelCol={{ md: { span: 10, offset: 0 }, xs: { span: 6, offset: 0 } }}
				label="Your Name:"
				name="name"
			>
				<Input
					// value={currentProfile?.name}
					autoComplete="given-name"
					autoCapitalize="true"
					placeholder="Your Name"
					style={{
						backgroundColor: "transparent",
						border: "1px solid #FFFFFF50",
					}}
				></Input>
			</Form.Item>
			<br />
			<Form.Item
				labelCol={{ md: { span: 10, offset: 0 }, xs: { span: 6, offset: 0 } }}
				label="Phone Number:"
				name="phone"
				validateStatus="success"
			>
				<InputNumber
					value={currentProfile?.phone || ""}
					placeholder="###-###-####"
					controls={false}
					autoComplete="tel-area-local"
					style={{
						width: "100%",
					}}
				></InputNumber>
			</Form.Item>
			<Form.Item>
				<Space>
					<Button type="primary" htmlType="submit">
						Save
					</Button>
				</Space>
			</Form.Item>

			<br />
		</Form>
	);
};

export default AccountDetailsForm;
