import { useState, useEffect, Suspense } from "react";
import {
	BrowserRouter as Router,
	Route,
	Routes,
	Navigate,
} from "react-router-dom";
import "./App.css";
import "./index.css";
import RecipePage from "./RecipePage";
import Login from "./Login";
import supabase from "./supabaseClient";
import { ConfigProvider, theme } from "antd";
import Loader from "./Loader";
import { useNavigate } from "react-router-dom";

import CreateRecipe from "./CreateRecipe";
import Discover from "./Discover";
import MyCookbook from "./MyCookbook";
import MyProfile from "./MyProfile";

export default function App() {
	const [session, setSession] = useState(null);
	const [loading, setLoading] = useState(true);
	const firstLocation = window.location.pathname;

	useEffect(() => {
		supabase.auth.getSession().then(({ data: { session } }) => {
			setSession(session);
			setLoading(false);
		});

		const {
			data: { subscription },
		} = supabase.auth.onAuthStateChange((_event, session) => {
			setSession(session);
			setLoading(false);
			localStorage.setItem("user", JSON.stringify(session?.user));
		});

		return () => subscription.unsubscribe();
	}, []);

	return (
		<>
			<Suspense fallback={<Loader spinning={loading} />}>
				<ConfigProvider
					theme={{
						algorithm: theme.darkAlgorithm,
						token: {
							colorPrimary: "#23c686",
							borderRadius: 6,
							colorLink: "#23c686",
							colorInfo: "#1a424f",
							controlItemBgActive: "#1a424f",
							controlItemBgActiveDisabled: "#1a424f",
							controlItemBgHover: "#1a424f",
							controlOutline: "FFFFFF50",
							colorBgLayout: "linear-gradient(180deg, #224b58, #4a6f7a)",
							colorBorder: "#FFFFFF50",
						},
						components: {
							Button: {
								colorPrimary: "#23c686",
							},
							Input: {
								colorPrimary: "#23c686",
								backgroundColor: "#1a424f",
								colorInfoBg: "#1a424f",
								algorithm: true,
								activeBg: "#1a424f",
								addonBg: "#1a424f",
								colorBgContainer: "#1a424f",
								// colorBorderBg: '#1a424f',
								// activeBorderColor:  '#1a424f',
							},
							colorBgContainer: "#1a424f",
							colorBgBase: "#FFFFFF",
						},
					}}
				>
					<Router>
						<Routes>
							<Route path="/recipe/:recipeId" element={<RecipePage />} />

							<Route
								path="/login"
								element={session ? <Navigate to="/" /> : <Login />}
							/>

							<Route path="/" element={<Discover />} />

							<Route
								path="/cookbook"
								element={
									<PrivateRoute session={session}>
										<MyCookbook />
									</PrivateRoute>
								}
							/>

							<Route
								path="/create"
								element={
									<PrivateRoute session={session}>
										<CreateRecipe />
									</PrivateRoute>
								}
							/>

							<Route
								path="/discover"
								element={
									<PrivateRoute session={session}>
										<Discover />
									</PrivateRoute>
								}
							/>

							<Route
								path="/profile"
								element={
									<PrivateRoute session={session}>
										<MyProfile />
									</PrivateRoute>
								}
							/>
						</Routes>
					</Router>
				</ConfigProvider>
			</Suspense>
		</>
	);
}

function PrivateRoute({ children, session }) {
	const navigate = useNavigate();

	useEffect(() => {
		if (!session) {
			navigate("/login", { replace: true });
		}
	}, [session, navigate]);

	return session ? children : <Navigate to="/login" replace={false} />;
}
