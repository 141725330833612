import supabase from "./supabaseClient";
import React, { useState } from "react";
import { notification } from "antd";

export const getUserProfile = async () => {
	const { data, error } = await supabase.from("profiles").select("*");

	if (data) {
		console.log(data[0]);
		return data[0];
	} else {
		console.log(error);
	}
};

export async function handleAccountChanges(values) {
	console.log(JSON.stringify(values));
	const user = await supabase.auth.getUser();

	const { data, error } = await supabase
		.from("profiles")
		.update(values)
		.eq("user_id", user.data.user.id)
		.select();

	if (data) {
		console.log(data);
		notification.success({
			message: "Success!",
			description: "Your profile information was saved.",
			duration: 3,
		});
	}

	if (error) {
		console.log(error);
	}
}

export async function addToCookbook(recipeObj) {
	const user = await supabase.auth.getUser();

	let recipe = {
		user_id: user.data.user.id,
		recipe_id: recipeObj.id,
		cookbook_id: "0",
	};

	const { data, error } = await supabase
		.from("user_cookbooks")
		.insert(recipe)
		.select();

	if (data) {
		console.log(data);
		notification.success({
			message: "Yummy!",
			description: "Added to your cookbook.",
			duration: 3,
		});
	}

	if (error) {
		console.log(error);
	}
}
