import React, { useState } from "react";
import { Drawer, Space, Flex, Tooltip } from "antd";
import { ArrowLeftOutlined, ShareAltOutlined } from "@ant-design/icons";
import ShareComponent from "./ShareComponent";
import stripHTML from "./stripHTML";

const ShareRecipeSlider = ({ recipe, label = "", handleRecipeClick }) => {
	const [open, setOpen] = useState(false);
	const showDrawer = () => {
		setOpen(true);
	};
	const onClose = () => {
		setOpen(false);
	};

	const IconText = ({ icon, text, tooltip = "", fx = null }) => (
		<Space
			onClick={fx ?? showDrawer}
			style={{
				cursor: "pointer",
			}}
			tooltip={tooltip}
		>
			<Tooltip title={tooltip}>
				{React.createElement(icon)}
				{text}
			</Tooltip>
		</Space>
	);

	return (
		<div>
			<div>
				<div className="recipe-card-buttons" style={{}}>
					<IconText
						icon={ShareAltOutlined}
						text={label}
						tooltip="Share recipe"
						// fx={() => showDrawer}
						key="save"
					/>
				</div>
			</div>

			<Drawer
				drawerStyle={{
					backdropFilter: "blur(6px)",
				}}
				style={{
					backgroundColor: "transparent",
				}}
				header={false}
				height="100%"
				mask={true}
				title={
					<div style={{ fontSize: "12px", lineHeight: 1.1 }}>
						{stripHTML(recipe.title)}
					</div>
				}
				headerStyle={{
					fontSize: "12px",
					textOverflow: "ellipsis",
					padding: "20px",
				}}
				contentWrapperStyle={{
					// padding: "5px",
					backgroundColor: "#00000090",
					backdropFilter: "blur(6px)",
					WebkitBackdropFilter: "blur(10px)",
				}}
				closeIcon={<ArrowLeftOutlined />}
				placement="bottom"
				closable={true}
				onClose={onClose}
				open={open}
				// getContainer={false}
			>
				<Flex
					gap="small"
					style={{
						width: "100%",
						justifyContent: "space-around",
						alignItems: "center",
						textAlign: "center",
						padding: "0",
						margin: "0",
						flexDirection: "column",
					}}
				>
					<ShareComponent recipe={recipe} />
				</Flex>
			</Drawer>
		</div>
	);
};
export default ShareRecipeSlider;
