export default function stripHTML(html) {
	// Remove script and style contents
	html = html?.replace(/<(script|style)[^>]*>[\s\S]*?<\/\1>/gi, "");

	// Remove HTML tags
	html = html?.replace(/<\/?[^>]+(>|$)/g, "");

	// Limit consecutive newlines to 4
	html = html?.replace(/(\s*\n\s*){2,}/g, "\n\n");

	html = html
		?.replace(/&nbsp;/g, " ")
		.replace(/&amp;/g, "&")
		.replace(/\\t/g, /\\n/g)
		.replace(/&shy;/g, "");

	html = html?.replace("html", "").replace(/```/g, "");

	return html;
}
