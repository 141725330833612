// eslint-disable-next-line
import React, { useState } from "react";
import { Form, Input, InputNumber, Radio } from "antd";

const RecipeStartForm = () => {
	const savedFormData = JSON.parse(localStorage.getItem("newRecipeForm"));

	let formData = savedFormData ?? {
		recipeType: "singleServing",
		servings: 1,
	};

	const [multiServing, setMultiServing] = useState(
		formData?.recipeType === "multiServing" || false,
	);

	const handleChange = (value, allValues) => {
		formData = allValues;

		if (formData.recipeType === "multiServing") {
			setMultiServing(true);
		} else {
			setMultiServing(false);
			formData["recipeType"] = "singleServing";
			formData["servings"] = 1;
		}

		// console.log(formData);
		const existingFormData = JSON.parse(localStorage.getItem("newRecipeForm"));
		localStorage.setItem(
			"newRecipeForm",
			JSON.stringify({ ...existingFormData, ...formData }),
		);
	};

	return (
		<Form
			labelCol={{}}
			wrapperCol={{}}
			layout="vertical"
			initialValues={formData}
			onValuesChange={handleChange}
			size="large"
			style={{
				width: "100%",
				textAlign: "left",
			}}
		>
			<h1>What do you want to eat?</h1>
			<Form.Item
				label="Enter the name of a food, meal, snack, or dessert that you'd like to eat."
				name="foodRequest"
			>
				<Input
					placeholder="Example: Chocolate Chip Cookies"
					allowClear={true}
				/>
			</Form.Item>

			<Form.Item label="Recipe Type" name="recipeType">
				<Radio.Group>
					<Radio.Button value="singleServing">Single Serving</Radio.Button>
					<Radio.Button value="multiServing">Multiple Servings</Radio.Button>
				</Radio.Group>
			</Form.Item>

			<Form.Item
				style={!multiServing ? { display: "none" } : { display: "block" }}
				label="Number of Servings"
				name="servings"
			>
				<InputNumber min={1} max={100} value={formData.servings} />
			</Form.Item>
		</Form>
	);
};

export default RecipeStartForm;
