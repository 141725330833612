import React, { useState } from "react";
import { Tabs, theme, FloatButton, notification } from "antd";
import "./CreateRecipe.css";
import RecipePreferencesForm from "./RecipePreferencesForm";
import RecipeExtrasForm from "./RecipeExtrasForm";
import { useNavigate } from "react-router-dom";
import NavBar from "./NavBar";
import AccountDetailsForm from "./AccountDetailsForm";

const steps = [
	{
		label: "Account",
		key: 0,
		children: <AccountDetailsForm />,
	},
	{
		label: "Preferences",
		key: 1,
		children: <RecipePreferencesForm />,
	},
	{
		label: "Extras",
		key: 2,
		children: <RecipeExtrasForm />,
	},
];

export default function MyProfile() {
	const { token } = theme.useToken();
	const [current, setCurrent] = useState(0);
	const items = steps.map((item) => ({
		key: item.key,
		label: item.label,
	}));
	const contentStyle = {
		lineHeight: "260px",
		textAlign: "center",
		color: token.colorTextTertiary,
		backgroundColor: token.colorFillAlter,
		borderRadius: token.borderRadiusLG,
		border: `1px dashed ${token.colorBorder}`,
		marginTop: 16,
	};

	const onChange = (value) => {
		// console.log("onChange:", value)
		setCurrent(value);
	};

	const navigate = useNavigate();

	const handleFormSubmit = async () => {
		console.log("Profile Changes Saved.");
	};

	return (
		<>
			<NavBar />
			<br />
			<h1 style={{ textAlign: "center" }}>My Profile</h1>

			<div className="create-recipe-container">
				<Tabs
					defaultActiveKey={current}
					centered={true}
					type="line"
					size="large"
					width="100%"
					current={current}
					items={items}
					onChange={onChange}
					animated={true}
					tabBarGutter={50}
					tabBarStyle={{
						borderBottom: "0.5px solid white",
					}}
				/>

				<div className="create-recipe-box" style={contentStyle}>
					{steps[current].children}
				</div>
			</div>
		</>
	);
}
