import RecipeOptionsSliderInline from "./RecipeOptionsSliderInline";
import "./ViewRecipe.css";
import stripHTML from "./stripHTML";

const ViewRecipe = () => {
	const selectedRecipe = JSON.parse(localStorage.getItem("selectedRecipe"));
	const imageURL = selectedRecipe?.image || "";
	const content = selectedRecipe?.content
		.replace(/\\n/g, "")
		.replace("Title:", "");
	const recipeHTML = content.replace(`<h1>`, `<h1 style="display: none;">`);
	const updatedTime = new Date(selectedRecipe?.createdAt).toLocaleString(
		"en-US",
		{
			month: "long",
			day: "numeric",
			year: "numeric",
			hour: "numeric",
			minute: "numeric",
		},
	);

	return (
		<>
			<div
				className="recipe-options-viewer"
				style={{
					background: "rgba(255,255,255,0)",
					// border: '2.5px solid white',
					color: "white",
					borderRadius: "20px",
					// boxShadow: '1px 3px 25px #ffffff50',
					// maxWidth: 1000,
					margin: "5px 50px",
					position: "sticky",
					top: 0,
					left: 0,
					right: 0,
					zIndex: 10,
				}}
			>
				<RecipeOptionsSliderInline recipe={selectedRecipe} />
			</div>

			<div className="selected-recipe-content" style={{ overflow: "hidden" }}>
				<div className="image-container" style={{ overflow: "hidden" }}>
					<img src={imageURL} alt={""} style={{ overflow: "hidden" }}></img>
				</div>

				<div className="recipe-section">
					<h1>{stripHTML(selectedRecipe.title)}</h1>

					<div className="recipe-meta">
						<p>Updated: {updatedTime}</p>
						{selectedRecipe?.diets?.length > 0 && (
							<p style={{ display: "inline" }}>
								Suitable for a{" "}
								<span style={{ fontWeight: "bold" }}>
									{selectedRecipe?.diets}
								</span>
							</p>
						)}
					</div>

					<br />
					<div
						className="recipe-content-body"
						dangerouslySetInnerHTML={{ __html: recipeHTML }}
					/>
				</div>
			</div>
		</>
	);
};

export default ViewRecipe;
