import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import supabase from "./supabaseClient";
import { Flex, Spin } from "antd";
import { Helmet } from "react-helmet";
import removeEmojis from "./removeEmojis";
import stripHTML from "./stripHTML";
import "./RecipePage.css";
import RecipeOptionsSliderInline from "./RecipeOptionsSliderInline";
import NavBar from "./NavBar";

const RecipePage = () => {
	const { recipeId } = useParams();
	const [recipe, setRecipe] = useState(null);

	useEffect(() => {
		const fetchRecipe = async () => {
			const { data, error } = await supabase
				.from("all_recipes")
				.select("*")
				.eq("recipeId", recipeId)
				.single();

			if (error) {
				console.log("Error fetching recipe:", error);
			} else setRecipe(data);
		};

		fetchRecipe();
	}, [recipeId]);

	if (!recipe)
		return (
			<Flex align="center" gap="middle">
				<Spin size="large" fullscreen={true} spinning={recipe} />
			</Flex>
		);

	const pageURL = window.location.href;
	const siteName = "CustomCraving";
	const pageTitle = stripHTML(removeEmojis(recipe.title)).trim();
	const recipeHTML = recipe.recipe.replace(
		`<h1>`,
		`<h1 style="display: none;">`,
	);
	const pageDescription = stripHTML(recipe.recipe);
	const pageImage = recipe.image;
	const pageKeywords = `${recipe?.diets?.join(", ")}, ${
		recipe.title
	}, Custom Craving, CustomCraving, ${recipe?.allergies?.join(", ")}, ${
		recipe.foodRequest
	},  `;
	const schema = {
		"@context": "http://schema.org/",
		"@type": ["Recipe", "NewsArticle"],
		headline: pageTitle,
		name: pageTitle,
		image: pageImage,
		author: {
			"@type": "Person",
			name: "CustomCraving",
		},
		datePublished: "PUBLISH_DATE",
		description: "RECIPE_DESCRIPTION",
		totalTime: "PT15M",
		keywords: pageKeywords,
		recipeYield: recipe.servings,
		recipeCategory: "RECIPE_CATEGORY",
		recipeCuisine: "RECIPE_CUISINE",
		nutrition: {
			"@type": "NutritionInformation",
			calories: "CALORIES_VALUE",
			fatContent: "FAT_CONTENT",
			carbohydrateContent: "CARBOHYDRATE_CONTENT",
			proteinContent: "PROTEIN_CONTENT",
			cholesterolContent: "CHOLESTEROL_CONTENT",
		},
		recipeIngredient: [
			"INGREDIENT_1",
			"INGREDIENT_2",
			"INGREDIENT_3",
			// ... more ingredients
		],
		recipeInstructions: [
			{
				"@type": "HowToStep",
				text: "INSTRUCTION_STEP_1",
			},
			{
				"@type": "HowToStep",
				text: "INSTRUCTION_STEP_2",
			},
			// ... more steps
		],
		aggregateRating: {
			"@type": "AggregateRating",
			ratingValue: "AVERAGE_RATING",
			reviewCount: "NUMBER_OF_REVIEWS",
		},
	};

	return (
		<>
			<Helmet>
				<title>{pageTitle} | Custom Craving</title>
				<link rel="canonical" href={window.location.href}></link>
				<meta
					name="description"
					content={pageDescription}
					itemProp="description"
				/>
				<meta name="keywords" content={pageKeywords} />
				<meta itemprop="name" content={pageTitle} />
				<meta property="article:section" content={siteName} />
				<meta itemprop="description" content={pageDescription} />
				<meta itemprop="image" content={pageImage} />
				<meta property="og:url" content={pageURL} />
				<meta property="og:type" content="article" />
				<meta property="og:title" content={pageTitle} />
				<meta property="og:description" content={pageDescription} />
				<meta property="og:image" content={pageImage} />
				<meta property="og:site_name" content={siteName} />
				<meta property="fb:app_id" content="your fb app id here" />
				<meta name="twitter:title" content={pageTitle} />
				<meta name="twitter:description" content={pageDescription} />
				<meta name="twitter:image" content={pageImage} />
				<meta name="twitter:site" content="@CustomCraving" />
				<meta name="twitter:creator" content="@CustomCraving" />
				<meta name="twitter:card" content="summary_large_image" />
				<script type="application/ld+json">{JSON.stringify(schema)}</script>
			</Helmet>

			<NavBar />

			<div className="recipe-page-container">
				<h1>{stripHTML(recipe.title)}</h1>

				<p>
					By: <i>CustomCraving</i> | Updated:{" "}
					{new Date(recipe.created_at).toLocaleString("en-US", {
						month: "long",
						day: "numeric",
						year: "numeric",
					})}
				</p>

				{recipe?.diets?.length > 0 && (
					<p>
						Suitable for a <b>{recipe?.diets.join(" • ")}</b>
					</p>
				)}

				<div className="recipe-page-content">
					<div className="recipe-page-image-container">
						<img src={recipe.image} alt={pageTitle} />

						<div className="recipe-page-options">
							<RecipeOptionsSliderInline recipe={recipe} />
						</div>
					</div>

					<div
						className="recipe-page-body"
						dangerouslySetInnerHTML={{ __html: recipeHTML }}
					/>
				</div>
			</div>
		</>
	);
};

export default RecipePage;
