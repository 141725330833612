import { Auth } from "@supabase/auth-ui-react";
import { ThemeSupa } from "@supabase/auth-ui-shared";
import logoWhite from "./CustomCraving_Logo_Original_White.png";
import supabase from "./supabaseClient";
import { useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import Loader from "./Loader";

export default function Login() {
	const navigate = useNavigate();
	const location = useLocation();
	const from = location.state?.from?.pathname || "/";
	console.log("from: " + from);
	//eslint-disable-next-line
  const [session, setSession] = useState(null);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		// Check the current session and update state
		supabase.auth.getSession().then(({ data: { session } }) => {
			setSession(session);
			setLoading(false);
		});

		// Listen for authentication state changes
		const {
			data: { subscription },
		} = supabase.auth.onAuthStateChange((_event, session) => {
			setSession(session);
			setLoading(false);
			localStorage.setItem("user", JSON.stringify(session?.user));

			// Redirect if there's a session (user has logged in)
			if (session) {
				navigate(from);
			}
		});

		return () => subscription.unsubscribe();
	}, [navigate, from]);

	if (loading) {
		return <Loader />; // Loading spinner or message
	}

	return (
		<div className="login-screen">
			<img className="logoWhite" src={logoWhite} alt="CustomCraving" />
			<Auth
				supabaseClient={supabase}
				appearance={{ theme: ThemeSupa }}
				providers={["google"]}
				theme="light"
			/>
		</div>
	);
}
