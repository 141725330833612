import { useEffect, useState } from "react";
import supabase from "./supabaseClient";
import React from "react";
import { List, Card, Modal } from "antd";
import ViewRecipe from "./ViewRecipe";
import ImageLoader from "./ImageLoader";
import RecipeOptionsSlider from "./RecipeOptionsSlider";
import NavBar from "./NavBar";
import stripHTML from "./stripHTML";
import loading from "./loading-infinity.gif";

const getRecipes = async () => {
	const { data } = await supabase.from("all_recipes").select();
	// console.log(JSON.stringify(data));
	return data || [];
};

function Discover() {
	const [recipes, setRecipes] = useState([]);
	useEffect(() => {
		getRecipes().then((data) => setRecipes(data));
	}, []);

	const [modal1Open, setModal1Open] = useState(false);

	const data = recipes.map((recipe) => ({
		key: recipe?.recipeId,
		recipeId: recipe?.recipeId,
		id: recipe.id,
		href: "",
		title: `${
			stripHTML(recipe?.recipe?.split("</h1>")[0])?.replace("Title: ", "") ??
			recipe?.foodRequest?.toUpperCase() ??
			"New Recipe"
		}`,
		content: `${recipe?.recipe}`,
		image: `${recipe?.image ?? loading ?? "https://iili.io/HZaJR6u.jpg"}`,
		status: recipe?.status,
		createdAt: recipe?.created_at,
		diets: recipe?.diets?.join(", ") ?? "Flexible Diet",
		allergies: recipe?.allergies?.join(", ") ?? "",
		macros: recipe?.macros,
	}));

	const handleRecipeClick = (recipeObj, event) => {
		event.preventDefault();
		localStorage.setItem("selectedRecipe", JSON.stringify(recipeObj));
		setModal1Open(true);
	};

	return (
		<>
			<NavBar />
			<br />
			<h1 style={{ textAlign: "center" }}>Discover Recipes</h1>
			<hr />
			<br />
			<br />
			<List
				grid={{
					gutter: 0,
					xs: 1,
					sm: 2,
					md: 2,
					lg: 3,
					xl: 3,
					xxl: 4,
				}}
				itemLayout="horizontal"
				size="medium"
				pagination={{
					onChange: (page) => {
						console.log(page);
					},
					pageSize: 50,
				}}
				loadMore={true}
				loading={recipes.length === 0}
				dataSource={data}
				footer={<div></div>}
				style={{
					margin: "0 auto",
					paddingBottom: 80,
				}}
				renderItem={(item) => (
					<>
						<List.Item key={item.key} actions={[]} extra={<div></div>}>
							<Card
								className="recipe-card"
								bordered={false}
								// title={<a href="/" onClick={ (event) => handleRecipeClick(item, event) }> {item.title}</a>}
								style={{
									boxShadow: "0 26px 30px rgba(0,0,0,0.1)",
									border: 0,
									// backgroundColor: '#4a6f7a',
									background: "lightgrey",
									color: "black",
									margin: "0 auto",
									marginBottom: "40px",
									overflow: "hidden",
								}}
							>
								{/* card content goes here */}
								<RecipeOptionsSlider
									recipe={item}
									handleRecipeClick={handleRecipeClick}
								/>

								<div className="recipe-image-container">
									<a
										href="/"
										onClick={(event) => handleRecipeClick(item, event)}
									>
										<ImageLoader
											className="recipe-image"
											alt={item?.title}
											src={`${item?.image || loading}`}
											style={{
												textAlign: "center",
												margin: "0",
												padding: "0",
												maxWidth: "100%",
												overflow: "hidden",
											}}
										/>
									</a>
								</div>

								<div
									className="recipe-card-title"
									style={{
										position: "absolute",
										zIndex: 10,
										textAlign: "center",
										width: "100%",
										padding: 10,
									}}
								>
									<a
										href="/"
										onClick={(event) => handleRecipeClick(item, event)}
									>
										{item.title}
									</a>
									<br />
									<br />
									<div
										style={{
											display: "flex",
											justifyItems: "center",
											alignItems: "center",
											justifyContent: "center",
											padding: 5,
											textAlign: "center",
											flexWrap: "wrap",
										}}
									>
										{/* DIETS LIST */}

										<br />
										{item.diets.split(", ").map((diet, index) => {
											return (
												<span
													key={index}
													style={{
														padding: "2px 5px",
														margin: "1.5px",
														backgroundColor: "#224b5870",
														color: "white",
														border: "0.1px dotted lightgrey",
														fontSize: "smaller",
														whiteSpace: "nowrap",
														fontWeight: 400,
														borderRadius: 5,
													}}
												>
													{diet}
												</span>
											);
										})}
									</div>
									{/* NUTRITION DETAILS */}
									<div
										style={{
											fontWeight: "400",
											fontSize: "smaller",
										}}
									>
										<br />
										{item?.macros?.maxCaloriesPerServing &&
											item?.macros.maxCaloriesPerServing +
												" calories per serving"}{" "}
									</div>
								</div>
							</Card>
						</List.Item>
					</>
				)}
			/>
			{modal1Open ? (
				<Modal
					className="recipe-modal"
					title=""
					style={{
						maxWidth: "800px",
					}}
					width={"85%"}
					open={modal1Open}
					closeIcon={true}
					destroyOnClose={true}
					onOk={() => setModal1Open(false)}
					onCancel={() => setModal1Open(false)}
					footer={null}
				>
					<ViewRecipe />
				</Modal>
			) : (
				<></>
			)}
		</>
	);
}

export default Discover;
