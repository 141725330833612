import styled from "styled-components";
import {
	LinkedinOutlined,
	FacebookOutlined,
	TwitterOutlined,
	MailOutlined,
	MessageOutlined,
	LinkOutlined,
} from "@ant-design/icons";
import { Flex } from "antd";
import React from "react";
import stripHTML from "./stripHTML";
import removeEmojis from "./removeEmojis";
import copyToClipboard from "./copyToClipboard";

export default function ShareComponent({ recipe }) {
	let recipeTitle = removeEmojis(stripHTML(recipe.title)).trim();

	let recipeUrl = window.location.origin + "/recipe/" + recipe.recipeId;
	let postCopy = encodeURIComponent(
		"Check out this recipe on CustomCraving: \n" + recipeTitle,
	);

	const socials = [
		{
			outlet: "LinkedIn",
			href: `https://www.linkedin.com/shareArticle?url=${recipeUrl}`,
			background: "#0a66c2",
			color: "white",
			label: "Share on LinkedIn",
			icon: <LinkedinOutlined />,
		},
		{
			outlet: "Facebook",
			href: `https://www.facebook.com/sharer.php?u=${recipeUrl}&quote=${postCopy}`,
			background: "#3b5898",
			color: "white",
			label: "Share on Facebook",
			icon: <FacebookOutlined />,
		},
		{
			outlet: "Twitter",
			href: `https://twitter.com/intent/tweet?url=${recipeUrl}&text=${postCopy}&via=CustomCraving`,
			background: "#00aced",
			color: "white",
			label: "Share on Twitter",
			icon: <TwitterOutlined />,
		},
		{
			outlet: "Email",
			href: `mailto:recipes@customcraving.com?subject=${encodeURIComponent(
				recipeTitle,
			)}&body=${postCopy + " "}${recipeUrl}`,
			background: "#dd4b39",
			color: "white",
			label: "Share via Email",
			icon: <MailOutlined />,
		},
		{
			outlet: "SMS",
			href: `sms:?body=${postCopy + " %0a" + recipeUrl}`,
			background: "#fff",
			color: "black",
			label: "Share via SMS",
			icon: <MessageOutlined />,
		},
		{
			outlet: "Copy Link",
			href: `#`,
			background: "#fff",
			color: "black",
			label: "Copy Link",
			icon: <LinkOutlined />,
			fx: (event) => {
				copyToClipboard(recipeUrl, event);
			},
		},
	].reverse();

	const socialLinks = socials.map((social, index) => {
		return (
			<div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
				<SocialLink
					as="a"
					href={social.href}
					target="_blank"
					rel="noreferrer"
					aria-label={social.label}
					role="button"
					isActive={true}
					background={social.background}
					color={social.color}
					position={index}
					key={index}
					onClick={social.fx}
				>
					{social.icon} &nbsp; {social.label}
				</SocialLink>
				{/* <br/> */}
			</div>
		);
	});

	return (
		<>
			<Flex
				gap="small"
				style={{
					width: "100%",
					justifyContent: "space-around",
					alignItems: "center",
					textAlign: "center",
					padding: "0",
					margin: "0",
					flexWrap: "wrap",
					flexDirection: "column",
				}}
			>
				{socialLinks}
			</Flex>
		</>
	);
}

const ShareButton = styled.button`
  display: flex;
  z-index: 1;
  align-items: center;
  justify-content: center;
  background: ${({ background }) => background};
  color: ${({ color }) => color};
  border-radius: 5px;
  outline: none;
  width: 70%;
  border: 2px solid ${({ background }) => background};
  padding: 8px;
  margin: 0 auto;
  transition: all 0.2s, transform 0.2s 0.2s;
  :hover {
    background: transparent;
    // color: white;
    }
  }
`;

const SocialLink = styled(ShareButton)`
	position: relative;
	z-index: 999999;
	transform: none;
`;
