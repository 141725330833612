import { message } from "antd";

export default async function copyToClipboard(string, event = null) {
	if (event) {
		event.preventDefault();
	}

	if (navigator.clipboard) {
		try {
			await navigator.clipboard.writeText(string);
			console.log("Text copied to clipboard");
			message.success("Link copied to clipboard");
			return;
		} catch (err) {
			console.error("Failed to copy text using Clipboard API: ", err);
		}
	}

	// Fallback to execCommand method
	const input = document.createElement("input");
	input.style.position = "fixed";
	input.style.opacity = "0";
	input.value = string;
	document.body.appendChild(input);
	input.select();
	try {
		const successful = document.execCommand("copy");
		const msg = successful ? "successful" : "unsuccessful";
		console.log("Copy command was " + msg);
		message.success("Link copied to clipboard");
	} catch (err) {
		console.error("Failed to copy text using execCommand: ", err);
	}
	document.body.removeChild(input);
}
