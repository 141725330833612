import React, { useState } from "react";
import { Space, Tooltip, message } from "antd";
import { StarOutlined } from "@ant-design/icons";
import ShareRecipeSlider from "./ShareRecipeSlider";

const RecipeOptionsSlider = ({ recipe, handleRecipeClick }) => {
	//eslint-disable-next-line
  const [open, setOpen] = useState(false);
	const showDrawer = () => {
		setOpen(true);
	};
	//eslint-disable-next-line
  const onClose = () => {
		setOpen(false);
	};
	const containerStyle = {
		position: "relative",
		// top: 0,
		// bottom: 365,
		height: "60px",
		paddingBottom: "40px",
		left: 0,
		right: 0,
		// maxWidth: "900px",
		// padding: 0,
		// overflow: 'hidden',
		textAlign: "center",
	};

	const IconText = ({ icon, text, tooltip = "", fx = null }) => (
		<Space
			onClick={fx ?? showDrawer}
			style={{
				cursor: "pointer",
			}}
			tooltip={tooltip}
		>
			<Tooltip title={tooltip}>
				{React.createElement(icon)}
				{text}
			</Tooltip>
		</Space>
	);

	return (
		<div style={containerStyle}>
			<div
				style={
					{
						//   marginTop: 16,
					}
				}
			>
				<div
					className="recipe-card-buttons"
					style={{
						display: "flex",
						position: "absolute",
						bottom: "20px",
						flexDirection: "row",
						width: "100%",
						alignItems: "stretch",
						justifyItems: "stretch",
						justifyContent: "space-around",
						overflow: "hidden",
					}}
				>
					<IconText
						icon={StarOutlined}
						text="  Save"
						tooltip="Save to your cookbook"
						fx={() => message.info("Create an account to save!")}
						key="save"
					/>

					<ShareRecipeSlider recipe={recipe} label="  Share" />
				</div>
			</div>

			{/* <Drawer
        drawerStyle={{
            backdropFilter: 'blur(6px)',
        }}
        style={{
            backgroundColor: 'transparent',
        }}
        header={false}
        height="100%"
        mask={true}
        title={<div style={{fontSize: "12px", lineHeight: 1.1}}>{stripHTML(recipe.title)}</div>}
        headerStyle={{ fontSize: "12px", textOverflow: 'ellipsis', padding: '10px'}}
        
        contentWrapperStyle={{
            // padding: "5px",
            backgroundColor: '#00000090',
            // backdropFilter: 'blur(6px)',
            // WebkitBackdropFilter: 'blur(10px)',
            
        }}
        closeIcon={<ArrowLeftOutlined/>}
        placement="bottom"
        closable={true}
        onClose={onClose}
        open={open}
        // getContainer={false}
        
      >
        <Flex gap="small" style={{
            width: "100%",
            justifyContent: "space-around",
            alignItems: "center",
            textAlign: "center",
            padding: "0",
            margin: "0",
            flexDirection: "column"
        }}>
            
            <Button 
                type="primary" 
                icon={<EyeOutlined/>}
                onClick={(event) => {handleRecipeClick(recipe, event)}}
                style={{
                  width: "100%"
                }}
            >
                View Recipe
            </Button>
            <Button style={{ width: "100%" }} icon={<LinkOutlined/>} href={`/recipe/${recipe.id}`} target='_blank'>
            Get Link</Button>

            { window.location.pathname === '/cookbook' ?
              <Button style={{ width: "100%" }} type="dashed" danger={true}>
                Delete Recipe
              </Button>
            : null }
            
        </Flex>

      </Drawer> */}
		</div>
	);
};
export default RecipeOptionsSlider;
