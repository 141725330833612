import "./Home.css";
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
	DashboardOutlined,
	ExperimentOutlined,
	LogoutOutlined,
	ReadOutlined,
	SearchOutlined,
	SettingOutlined,
	UserAddOutlined,
	UserOutlined,
} from "@ant-design/icons";
import { Menu } from "antd";

export default function NavBar() {
	const navigate = useNavigate();

	const [windowSize, setWindowSize] = useState({
		width: window.innerWidth,
		height: window.innerHeight,
	});

	useEffect(() => {
		// Handler to call on window resize
		const handleResize = () => {
			setWindowSize({
				width: window.innerWidth,
				height: window.innerHeight,
			});
			return windowSize;
		};

		// Add event listener
		window.addEventListener("resize", handleResize);

		// Remove event listener on cleanup
		return () => window.removeEventListener("resize", handleResize);
		//eslint-disable-next-line
  }, []); // Empty array ensures that effect is only run on mount and unmount

	const currentUserObj =
		localStorage.getItem("user") !== "undefined"
			? JSON.parse(localStorage.getItem("user"))
			: null;
	const userFullName =
		currentUserObj?.user_metadata?.full_name ||
		currentUserObj?.email ||
		"Welcome!";
	const userEmail = currentUserObj?.email || "";

	const getSelectedPath = () => {
		const slug = window.location.pathname.substring(1);
		return slug ?? "discover";
	};

	const [current, setCurrent] = useState(getSelectedPath);

	// Configuring the links for the dropdown menu only
	const onClick = (e) => {
		// console.log('click ', e);
		setCurrent(e.key);
		if (e.key === "login") {
			localStorage.clear();
			sessionStorage.clear();
			window.location.replace("/login");
			navigate("/login", { replace: true });
		}
		if (e.key === "profile") {
			navigate("/profile", { replace: true });
		}
	};

	let items = [
		{
			label: "Discover",
			key: "discover",
			icon: (
				<Link to="/discover">
					<SearchOutlined />
				</Link>
			),
			to: "/discover",
		},
		{
			label: <>Create Recipe</>,
			key: "create",
			icon: (
				<Link to="/create">
					<ExperimentOutlined />
				</Link>
			),
			to: "/create",
		},
		{
			label: <>My Cookbook</>,
			key: "cookbook",
			icon: (
				<Link to="/cookbook">
					<ReadOutlined />
				</Link>
			),
			to: "/cookbook",
		},
		{
			label: (
				<span style={{ textDecoration: "none", color: "white" }}>Account</span>
			),
			key: "account",
			icon: <SettingOutlined />,
			children: [
				{
					type: "group",
					label: (
						<>
							<b>{userFullName}</b>
							<br />
							{userEmail}
						</>
					),

					children: [
						{
							label: "My Profile",
							key: "profile",
							icon: <UserOutlined />,
							to: "/profile",
						},
						{
							label: currentUserObj ? "Log Out" : "Sign Up / Log In",
							key: "login",
							icon: currentUserObj ? <LogoutOutlined /> : <UserAddOutlined />,
							to: "/login",
						},
					],
				},
			],
		},
	];

	return (
		<>
			<div
				className="navBar"
				style={
					{
						// position: 'absolute'
					}
				}
			>
				<Menu
					onClick={onClick}
					selectedKeys={[current]}
					mode="horizontal"
					// Hide label in ant menu on small screens
					items={items.map((item) => ({
						...item,
						label:
							windowSize.width > 575 ? (
								<Link to={item.to}>{item.label}</Link>
							) : (
								""
							),
					}))}
					style={{
						maxWidth: "100vw",
						flex: "auto",
						backgroundColor: "#153842",
					}}
				/>
			</div>
		</>
	);
}
